import React from "react"
import Layout from "../../components/layout/layout"

import Seo from "../../components/layout/seo";

export default function Imprint() {
  return (
    <Layout showBottom="false">
      <Seo title="Drops | Widerruf | Toothpaste Drops" path="/pages/disclaimer" description="Über 5000 zufriedene Kunden - Natürlich - Plastikfrei - Ohne Tierversuche - Vegan - Zahnpasta Drops. Hilf uns den Plastikmüll in unserer Umwelt & den Meeren zu beenden. #weltretter" />
      <div style={{ minHeight: 'calc(100vh - 355px)' }}>
        <div style={{ paddingTop: '100px'}} className="container mb-5">
          <h1>Widerruf</h1>
          <div className="title-condition">
            <p><strong>Widerrufsbelehrung</strong></p>
            <p><strong>Widerrufsrecht</strong></p>
            <p>Sie haben das Recht, binnen drei&szlig;ig Tagen ohne Angabe von Gr&uuml;nden diesen Vertrag zu widerrufen. Die Widerrufsfrist betr&auml;gt  drei&szlig;ig Tage ab dem Tag an dem Sie oder ein von Ihnen benannter Dritter, der nicht der Beförderer ist, die Waren in Besitz genommen haben bzw. hat.<br /> </p>
            <p>Um Ihr Widerrufsrecht auszu&uuml;ben, m&uuml;ssen Sie uns (Routinery GmbH, Weinbergsweg 4a, 10119 Berlin, Deutschland, info@routinery.de, Telefon: 030-93683498) mittels einer eindeutigen Erkl&auml;rung (z.B. ein mit der Post versandter Brief, Telefax oder E-Mail) &uuml;ber Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie k&ouml;nnen daf&uuml;r das beigef&uuml;gte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist. </p>
            <p>Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung &uuml;ber die Aus&uuml;bung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.</p>
            <p><strong>Folgen des Widerrufs </strong></p>
            <p>Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschlie&szlig;lich der Lieferkosten (mit Ausnahme der zus&auml;tzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, g&uuml;nstigste Standardlieferung gew&auml;hlt haben), unverz&uuml;glich und sp&auml;testens binnen vierzehn Tagen ab dem Tag zur&uuml;ckzuzahlen, an dem die Mitteilung &uuml;ber Ihren Widerruf dieses Vertrags bei uns eingegangen ist. F&uuml;r diese R&uuml;ckzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der urspr&uuml;nglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdr&uuml;cklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser R&uuml;ckzahlung Entgelte berechnet. Wir können die Rückzahlung verweigern, bis wir die Waren wieder zurückerhalten haben oder bis Sie den Nachweis erbracht haben, dass Sie die Waren zurückgesandt haben, je nachdem, welches der frühere Zeitpunkt ist.<br /> Sie tragen die unmittelbaren Kosten der R&uuml;cksendung der Waren. Sie m&uuml;ssen f&uuml;r einen etwaigen Wertverlust der Waren nur aufkommen, wenn dieser Wertverlust auf einen zur Pr&uuml;fung der Beschaffenheit, Eigenschaften und Funktionsweise der Waren nicht notwendigen Umgang mit ihnen zur&uuml;ckzuf&uuml;hren ist. </p>
            <table border="1" cellpadding="1" cellspacing="1" style={{width:'100%'}}>
               <tbody>
                  <tr>
                     <td>
                        <p><strong>Muster-Widerrufsformular</strong></p>
                        <p><em>(Wenn Sie den Vertrag widerrufen wollen, dann f&uuml;llen Sie bitte dieses Formular aus und senden Sie es zur&uuml;ck.)</em></p>
                        <p>&ndash; An Routinery GmbH, Weinbergsweg 4a, 10119 Berlin, Deutschland, info@routinery.de</p>
                        <p>&ndash; Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag &uuml;ber den Kauf der folgenden<br /> Waren (*)/die Erbringung der folgenden Dienstleistung (*)</p>
                        <p>&ndash; Bestellt am (*)/erhalten am (*)</p>
                        <p>&ndash; Name des/der Verbraucher(s)</p>
                        <p>&ndash; Anschrift des/der Verbraucher(s)</p>
                        <p>&ndash; Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)</p>
                        <p>&ndash; Datum<br /> <br /> <em>(*) Unzutreffendes streichen.</em></p>
                     </td>
                  </tr>
               </tbody>
            </table>
            <p>Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen vierzehn Tagen ab dem Tag, an dem Sie uns über den Widerruf dieses Vertrags unterrichten, an uns oder an MRO Versand GmbH (Wickerer Weg 11, 65719 Hofheim am Taunus, Deutschland) zurückzusenden oder zu übergeben. Die Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von vierzehn Tagen absenden.  </p>
            <p><strong>Besondere Hinweise</strong><br />Wenn Sie diesen Vertrag durch ein Darlehen finanzieren und ihn später widerrufen, sind sie auch an den Darlehensvertrag nicht mehr gebunden, sofern beide Verträge eine wirtschaftliche Einheit bilden. Dies ist insbesondere dann anzunehmen, wenn wir gleichzeitig Ihr Darlehensgeber sind oder wenn sich Ihr Darlehensgeber im Hinblick auf die Finanzierung unserer Mitwirkung bedient. Wenn uns das Darlehen bei Wirksamwerden des Widerrufs bereits zugeflossen ist, tritt Ihr Darlehensgeber im Verhältnis zu Ihnen hinsichtlich der Rechtsfolgen des Widerrufs oder der Rückgabe in unsere Rechte und Pflichten aus dem finanzierten Vertrag ein. Letzteres gilt nicht, wenn der vorliegende Vertrag den Erwerb von Finanzinstrumenten (z.B. von Wertpapieren, Devisen oder Derivaten) zum Gegenstand hat.<br /></p>
            <p>Wollen Sie eine vertragliche Bindung so weitgehend wie möglich vermeiden, machen Sie von Ihrem Widerrufsrecht Gebrauch und widerrufen Sie zudem den Darlehensvertrag, wenn Ihnen auch dafür ein Widerrufsrecht zusteht.<br /><br /></p>
            <p><strong>Abweichende R&uuml;cksendekostenregelung f&uuml;r Deutschland</strong><br /> Wir tragen die unmittelbaren Kosten der R&uuml;cksendung der Ware, wenn die R&uuml;cksendung innerhalb Deutschlands erfolgt.</p>
            <p><strong>Rücksendeetiketten</strong><br /> Unbeschadet Ihrer gesetzlichen Rechte stellen wir Ihnen kostenlose Rücksendeetiketten für Ihre Rücksendung innerhalb Deutschlands zur Verfügung.  Wir &uuml;bermitteln Ihnen das R&uuml;cksendeetikett per E-Mail. Die R&uuml;cksendung k&ouml;nnen Sie in einem Paketshop des jeweiligen Anbieters in Deutschland abgeben. <br /></p>
            <p><i><a target="_blank" rel="noreferrer" title="Widerrufsbelehrung" href="https://legal.trustedshops.com/produkte/rechtstexter">Widerrufsbelehrung</a> erstellt mit dem <a target="_blank" rel="noreferrer" title="Trusted Shops" href="https://legal.trustedshops.com/">Trusted Shops</a> Rechtstexter in Kooperation mit <a target="_blank" title="FÖHLISCH Rechtsanwälte" href="https://foehlisch.com" rel="noreferrer nofollow">FÖHLISCH Rechtsanwälte</a>.</i></p>
          </div>
        </div>
      </div>
    </Layout>
  )
}
